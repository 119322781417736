import React, {useState , useEffect} from 'react';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
// import p1 from '../assets/images/payment-icon/1.png';
// import p2 from '../assets/images/payment-icon/2.png';
// import p3 from '../assets/images/payment-icon/3.png';
// import p4 from '../assets/images/payment-icon/4.png';
// import p5 from '../assets/images/payment-icon/5.png';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import StripeCheckout from "react-stripe-checkout"
// import axios from "axios"
// import logo1 from '../assets/images/logo1.png';
// import { red } from '@material-ui/core/colors';
import HeroPages from "../components/HeroPages";

// import { GoSellElements  } from "@tap-payments/gosell";


// import { GoSell } from "@tap-payments/gosell";


// import myJavaScriptFile from './text.js';




const Checkout = (props) => {
    const [fname , setfName ] = useState("")
    const [Lname , setLName ] = useState("")
    const [Address , setAddress ] = useState("")
    const [Address1 , setAddress1 ] = useState("")
    const [Country , setCountry ] = useState("")
    const [ZipPostal , setZipPostal ] = useState("1")
    const [Email , setEmail ] = useState("")
    const [Phone , setPhone ] = useState("")
    const [DIFFERENTfname , setDIFFERENTfName ] = useState("")
    const [DIFFERENTLname , setDIFFERENTLName ] = useState("")
    const [DIFFERENTAddress , setDIFFERENTAddress ] = useState("")
    const [DIFFERENTAddress1 , setDIFFERENTAddress1 ] = useState("")
    const [DIFFERENTZipPostal , setDIFFERENTZipPostal ] = useState("")
    const [DIFFERENTEmail , setDIFFERENTEmail ] = useState("")
    const [DIFFERENTPhone , setDIFFERENTPhone ] = useState("")
    const [admindataall , setadmindataall ] = useState(0)

    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])

    const [photo1 , setphoto1 ] = useState("")
    const [discount , setdiscount ] = useState({})
    const [photo2 , setphoto2 ] = useState("")

    const [Order_Notes , setOrder_Notes ] = useState("")
    const [City , setCity ] = useState("")
    const [DIFFERENTOrder_Notes , setDIFFERENTOrder_Notes ] = useState("")
    const [DIFFERENTCity , setDIFFERENTCity ] = useState("")
    const [paymenttype , setpaymenttype ] = useState("")
    const [cart , setCart] = useState([])
    const [tot , setot] = useState(0)
    const [total , settotal] = useState(0)
    const [doctor , setdoctor] = useState(0)

    const [display , setdisplay] = useState(true)
    const [play , setplay] = useState(false)
    const [customPhotojson , setcustomPhotojson] = useState(false)
    const [Num1111 , setNum1111] = useState(0)
    const [Num11 , setNum11] = useState(0)
    const [SubTotal , setSubTotal] = useState(0)
    const [Tot1 , setTot1] = useState(0)
    const [chack, setchack] = useState(false) 
    const [chack1, setchack1] = useState(false) 
    const [chack2, setchack2] = useState(false) 
    const [chack3, setchack3] = useState(false) 
    const [paymenttype3, setpaymenttype3] = useState(false) 
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")




    useEffect(() =>{
        window.scrollTo(0, 0)
        // document.getElementById("c").style.display = "none"
        var data =  JSON.parse(localStorage.getItem("Cart")) 
        // var data1 =  JSON.parse(localStorage.getItem("CartPrice")) 
        if (data && data.length > 0) {
            setCart(data)


        
          fetch("https://motor-club-main-back.vercel.app/MainCatogories",{
              method: "GET",
               headers :  {
               "Content-Type" : "application/json" , 
           } ,
          })
          .then(res2=>res2.json())
          .then(res3=>{
              console.log(res3);
              // setMainCatogories(res3)
              
  
              let dat = []
              res3.map((res55,c)=>{
                  dat.push(res55)
              })
              // console.log(SubClassCategories,asse)
              console.log(dat)
              let data = dat.sort((a, b) => {
                  return a.sort - b.sort;
              });
              setMainCatogories(data)
              
          })
          fetch("https://motor-club-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            // console.log(res3);
            // const Data1 = res3.filter((res4,i)=>{
            //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
            //     return res4
            // })
            // console.log(Data1,res3)
            setSubCategories(res3)
            // setSubCategoriesname(res3)
            fetch("https://motor-club-main-back.vercel.app/MainCatogories",{
              method: "GET",
               headers :  {
               "Content-Type" : "application/json" , 
           } ,
          })
          .then(res12=>res12.json())
          .then(res13=>{
            let a = []
            // console.log(res3)
            for (let i = 0; i < res13.length; i++) {
            for (let j = 0; j < res3.length; j++) {
              // console.log(res3[j].MainCategories , res13[i].MainCategories)
              if(res3[j].MainCategories === res13[i].MainCategories){
                // a.push("1")
  // console.log(i,j)
                a[i] = "1"
                break
              }
              else{
                a[i] = "0"
                // a.pusH(("0"))
              }
              
            }
            }
            // console.log(a)
            setCate1(a)
          })
        })

        
            fetch("https://motor-club-main-back.vercel.app/llUserCheckoutData",{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res181=>res181.json())
            .then(res191=>{
                // setNum1111(res191.length)
                let date = new Date()
                let day= date.getDate() 
                let month= (date.getUTCMonth()+1)
                let year= date.getUTCFullYear()
                if(date.getDate() < 10) day = "0"+ date.getDate()
                if(date.getUTCMonth()+1 < 10) month = "0"+ (date.getUTCMonth()+1)
                
                let c = day +""+month+ ""+(year -2000)
                const f = res191.filter((res,i)=>res.Date === c)
                console.log(f.length+1,c)
                setNum1111(f.length+2)
                setNum11(c)
            
            })
            // setot(JSON.parse(localStorage.getItem("CartPrice")) )
            // if(JSON.parse(localStorage.getItem("Custom Img")) )
            // setcustomPhotojson(true )

            // data.map((res,i)=>{
            //     if(res.Product_Catagories === "Custom Products"){
            //         setplay(true)
            //     }
            // })
            fetch("https://motor-club-main-back.vercel.app/llUserCheckoutData",{
                method: "GET",
                 headers :  {
                 "Content-Type" : "application/json" , 
             } ,
            })
            .then(res181=>res181.json())
            .then(res191=>{
                setNum1111(res191.length)
            })
            // fetch("https://chand-rentacar-main-back.vercel.app/MainCatogories",{
            //     method: "GET",
            //      headers :  {
            //      "Content-Type" : "application/json" , 
            //  } ,
            // })
            // .then(res2=>res2.json())
            // .then(res3=>{
            //     console.log(res3);
            //     // setMainCatogories(res3)
                
    
            //     let dat = []
            //     res3.map((res55,c)=>{
            //         dat.push(res55)
            //     })
            //     // console.log(SubClassCategories,asse)
            //     console.log(dat)
            //     let data = dat.sort((a, b) => {
            //         return a.sort - b.sort;
            //     });
            //     setMainCatogories(data)
                
            // })
//   fetch("https://motor-club-main-back.vercel.app/dmindata",{
//     method: "GET",
//      headers :  {
//      "Content-Type" : "application/json" , 
//  } ,
// })
// .then(res18=>res18.json())
// .then(res19=>{
//     setdoctor(res19[0].condition)
//     console.log(res19[0].DeliveryCharges,JSON.parse(localStorage.getItem("CartPrice")));
//   setadmindataall(res19[0].DeliveryCharges)

  let j = 0
  // if(JSON.parse(localStorage.getItem("CartPrice")) < res19[0].condition){
  //               j = res19[0].DeliveryCharges
  //           }
  //           else{
    //               j = 0
    //           }
    console.log(data)
    data.map((item,i)=>{
               j = item.Total_Product_Price + j
            //    if (item.doctor_prescription){
            //     localStorage.setItem("doctor",JSON.stringify(true))
            //    }
          })
            // var doc =  JSON.parse(localStorage.getItem("doctor")) 
            // setdoctor(doc)
            setSubTotal(j)
            
            j =  j+  +parseInt(parseInt(j) *( 5/100))
            if(JSON.parse(localStorage.getItem("discount")) && !JSON.parse(localStorage.getItem("discount")).discount2 ){
                setdiscount(JSON.parse(localStorage.getItem("discount")))
                var d = document.getElementById("discount")
                if(JSON.parse(localStorage.getItem("discount")).discount > 1){
                    d.innerHTML = " Rs <b style='font-size:20px'></b>" +JSON.parse(localStorage.getItem("discount")).discount
                    // document.getElementById("ci11").style.visibility = "visible"
                    j = j - parseInt(JSON.parse(localStorage.getItem("discount")).discount)
                }
                else{
                    d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount1 +" % Off"
                    j =j - (j*(JSON.parse(localStorage.getItem("discount")).discount1)/100)
                    
                }
            }else{
                var d = document.getElementById("discount")
                d.innerHTML = "No discount"
                
            }
            
            
            if(JSON.parse(localStorage.getItem("discount")) && JSON.parse(localStorage.getItem("discount")).discount2 ){
                setdiscount(JSON.parse(localStorage.getItem("discount")))
            var d = document.getElementById("discount")
           
            d.innerHTML = " " +JSON.parse(localStorage.getItem("discount")).discount2 +" % Off"
            j =j - (j*(JSON.parse(localStorage.getItem("discount")).discount2)/100)
            


                }




            setot(j.toFixed(0))
            // var t = document.getElementById("subtotal")
            // t.innerHTML = "Rs "+j
            var f = document.getElementById("total")
            f.innerHTML = "Rs " +j.toFixed(0)
              setTot1(j)
        // })

        }
        // console.log(total,cart,j)
   else{
    swal("Cart Is Empty")
    props.history.push("/")
            // var t = document.getElementById("subtotal")
            // t.innerHTML = "Rs "+0
            var f = document.getElementById("total")
            f.innerHTML = "Rs " +0
   }
  },[])
  

//   useEffect(() => {
  
//     return () => {
//         localStorage.removeItem("doctor")
//     }
//   }, [])


    const SubmitDataMessage1 = (e) =>{
        e.preventDefault()
             console.log(cart,6787 ,customPhotojson, play,tot ,JSON.parse(localStorage.getItem("CartPrice"))-tot , JSON.parse(localStorage.getItem("CartPrice")))
      

if(chack1){
     if (cart.length  >= 1 ){

              if(fname === ""){
                swal("Fill The Name");
                 return
             }
             if(Email === ""){
               setEmail("noemail@gmail.com")
              }
              if(Phone === ""){
                 swal("Fill The Phone");
                  return
              }
            
             if(Address === ""){
                swal("Fill The Address");
                 return
             }
             if(City === ""){
                swal("Fill The City");
                 return
             }

                setdisplay(false)
               let j
        if(admindataall > 0){
                 j = admindataall
            }
            else{
                 j = 0
            }
           
            let differnet = {
                DIFFERENTfname,
                DIFFERENTLname ,
                DIFFERENTAddress ,
                DIFFERENTAddress1 ,
                DIFFERENTZipPostal : 1111 ,
                DIFFERENTCity,
                DIFFERENTEmail ,
                DIFFERENTPhone ,
                DIFFERENTOrder_Notes,
            }
                
                console.log(differnet,                Num11," tfgrtg  ",Num1111,
                )



                let das = {}

                if(discount.discount){
                  if(discount.discount > 1)
                das= {
                  name : discount.discount ,
                  type : "R"
                }
                
                // else
                // t = t -  (t * (discount.discount1 / 100))
                
                }
                
                if(discount.discount1)
                das= {
                  name : discount.discount1 ,
                  type : "P"
                }
                
                
                // if(discount.discount2)
                // t = t -  (t * (discount.discount1 / 100))
                


// payment method here 



                    
                                fetch("https://motor-club-main-back.vercel.app/UsergetDatafromclient1",{
                        method: "POST",
                        headers :  {
                            "Content-Type" : "application/json" , 
                        } ,
                        body : JSON.stringify({
                            fname,
                            Lname ,
                            Address ,
                            Address1 ,
                            ZipPostal : 1111 ,
                            City,
                            Email ,
                            Das: das,
                            Phone ,
                            differnet ,
                            Order_Notes,
                            DeliveryCharges:j,
                            discount ,
                               AfterDiscount :  tot ,
                               LessAmount :  JSON.parse(localStorage.getItem("CartPrice"))-tot ,
                               TotalAmount  : JSON.parse(localStorage.getItem("CartPrice")) ,
                            
                            Order : cart,
                            Num123 : Num11+""+Num1111+1,
                        }),
                    })
                    .then(res=>res.json())
                    .then((res2)=>{        
                    if(!res2.Error){
                       
                        setdisplay(true)
                        swal("Booking placed Successfully. Please make the payment to proceed with the booking. After transferring the payment, kindly send the screenshot to this WhatsApp number: +92 326 3091067.");
                       

                            setfName ("")
                            setLName ("" )
                            setAddress  ("")
                            setAddress1 ("" )
                            setZipPostal ("" )
                            setEmail ("" )
                            setCity ("" )
                            setPhone ("" )
                            setOrder_Notes ("")
                        localStorage.removeItem("Cart")
                        localStorage.removeItem("doctor")
                        localStorage.removeItem("SearchData")
                        localStorage.removeItem("Data")
                        localStorage.removeItem("CartPrice")
                        localStorage.removeItem("CateProduct")
                        localStorage.removeItem("discount")
                        localStorage.removeItem("Custom Img")
                        // GoSell.openPaymentPage()
                        props.history.push(`/thankyou`)

                     }
                     else{
                      console.log("3")
                        setdisplay(true)
                        swal("Plz Refresh And try Again the Order");
                     }
                  })
                  .catch(err=>{
                    setdisplay(true)
                    swal("Reload This Page");
                })
            }
           
            else{
                swal("There is No Any Serives in Your Cart");
                setTimeout(()=>{
                    
                    props.history.push(`/`)
                },2000)
            }
        // })
        // }
       
}
else{
  swal("Plz Read the Terms & Condition")
}

         
    }

    const SubmitDataMessage2 = (e) =>{
        e.preventDefault()
             console.log(cart,6787 ,customPhotojson, play,tot ,JSON.parse(localStorage.getItem("CartPrice"))-tot , JSON.parse(localStorage.getItem("CartPrice")))
      

if(chack1){
     if (cart.length  >= 1 ){

              if(fname === ""){
                swal("Fill The Name");
                 return
             }
             if(Email === ""){
               setEmail("noemail@gmail.com")
              }
              if(Phone === ""){
                 swal("Fill The Phone");
                  return
              }
            
             if(Address === ""){
                swal("Fill The Address");
                 return
             }
             if(City === ""){
                swal("Fill The City");
                 return
             }

                setdisplay(false)
               let j
        if(admindataall > 0){
                 j = admindataall
            }
            else{
                 j = 0
            }
           
            let differnet = {
                DIFFERENTfname,
                DIFFERENTLname ,
                DIFFERENTAddress ,
                DIFFERENTAddress1 ,
                DIFFERENTZipPostal : 1111 ,
                DIFFERENTCity,
                DIFFERENTEmail ,
                DIFFERENTPhone ,
                DIFFERENTOrder_Notes,
            }
                
                console.log(differnet,                Num11," tfgrtg  ",Num1111,
                )



                let das = {}

                if(discount.discount){
                  if(discount.discount > 1)
                das= {
                  name : discount.discount ,
                  type : "R"
                }
                
                // else
                // t = t -  (t * (discount.discount1 / 100))
                
                }
                
                if(discount.discount1)
                das= {
                  name : discount.discount1 ,
                  type : "P"
                }
                
                
                // if(discount.discount2)
                // t = t -  (t * (discount.discount1 / 100))
                


// payment method here 



                    
                                fetch("https://motor-club-main-back.vercel.app/UsergetDatafromclient1",{
                        method: "POST",
                        headers :  {
                            "Content-Type" : "application/json" , 
                        } ,
                        body : JSON.stringify({
                            fname,
                            Lname ,
                            Address ,
                            Address1 ,
                            ZipPostal : 1111 ,
                            City,
                            Email ,
                            Das: das,
                            Phone ,
                            differnet ,
                            Order_Notes,
                            DeliveryCharges:j,
                            discount ,
                               AfterDiscount :  tot ,
                               LessAmount :  JSON.parse(localStorage.getItem("CartPrice"))-tot ,
                               TotalAmount  : JSON.parse(localStorage.getItem("CartPrice")) ,
                            
                            Order : cart,
                            Num123 : Num11+""+Num1111+1,
                        }),
                    })
                    .then(res=>res.json())
                    .then((res2)=>{        
                    if(!res2.Error){
                       
                        setdisplay(true)
                        swal("Booking placed Successfully. Please make the payment to proceed with the booking. After transferring the payment, kindly send the screenshot to this WhatsApp number: +971 56 361 9373.");
                       

                            setfName ("")
                            setLName ("" )
                            setAddress  ("")
                            setAddress1 ("" )
                            setZipPostal ("" )
                            setEmail ("" )
                            setCity ("" )
                            setPhone ("" )
                            setOrder_Notes ("")
                        localStorage.removeItem("Cart")
                        localStorage.removeItem("doctor")
                        localStorage.removeItem("SearchData")
                        localStorage.removeItem("Data")
                        localStorage.removeItem("CartPrice")
                        localStorage.removeItem("CateProduct")
                        localStorage.removeItem("discount")
                        localStorage.removeItem("Custom Img")
                        // GoSell.openPaymentPage()
                        props.history.push(`/thankyou`)

                     }
                     else{
                      console.log("3")
                        setdisplay(true)
                        swal("Plz Refresh And try Again the Order");
                     }
                  })
                  .catch(err=>{
                    setdisplay(true)
                    swal("There is an Error");
                })
            }
           
            else{
                swal("There is No Any Serives in Your Cart");
                setTimeout(()=>{
                    
                    props.history.push(`/`)
                },2000)
            }
        // })
        // }
       
}
else{
  swal("Plz Read the Terms & Condition")
}

         
    }
  
//     const SubmitDataMessage4 = (e) =>{
//         e.preventDefault()





// var tap = Tapjsli('pk_test_EtHFV4BuPQokJT6jiROls87Y');

// var elements = tap.elements({});
// var style = {
//   base: {
//     color: '#535353',
//     lineHeight: '18px',
//     fontFamily: 'sans-serif',
//     fontSmoothing: 'antialiased',
//     fontSize: '16px',
//     '::placeholder': {
//       color: 'rgba(0, 0, 0, 0.26)',
//       fontSize:'15px'
//     }
//   },
//   invalid: {
//     color: 'red'
//   }
// };

// var labels = {
//     cardNumber:"Card Number",
//     expirationDate:"MM/YY",
//     cvv:"CVV",
//     cardHolder:"Card Holder Name"
//   };

//   var paymentOptions = {
//   currencyCode:["KWD","USD","SAR"],
//   labels : labels,
//   TextDirection:'ltr'
// }

// var card = elements.create('card', {style: style},paymentOptions);

// card.mount('#element-container');

// card.addEventListener('change', function(event) {
//   if(event.BIN){
//     console.log(event.BIN)
//   }
//   if(event.loaded){
//     console.log("UI loaded :"+event.loaded);
//     console.log("current currency is :"+card.getCurrency())
//   }
//   var displayError = document.getElementById('error-handler');
//   if (event.error) {
//     displayError.textContent = event.error.message;
//   } else {
//     displayError.textContent = '';
//   }
// });


// var form = document.getElementById('form-container');
// form.addEventListener('submit', function(event) {
//   event.preventDefault();

//   tap.createToken(card).then(function(result) {
//     console.log(result);
//     if (result.error) {

//       var errorElement = document.getElementById('error-handler');
//       errorElement.textContent = result.error.message;
//     } else {

//       var errorElement = document.getElementById('success');
//       errorElement.style.display = "block";
//       var tokenElement = document.getElementById('token');
//       tokenElement.textContent = result.id;
//       console.log(result.id);
//     }
//   });
// });
         
//     }
  const setchack21 = () =>{
      setchack2(!chack2)
      // setchack3(!check3)
  }
  const setchack31 = () =>{
      setchack3(!chack3)
      // setchack3(!check3)
  }


const callbackFunc = (response)=> {
  console.log(response);
}

    return (
      <div className="page-wrapper">
      
      <main className="main">
      <HeroPages name="Booking Checkout" />
        <br/>
        {/* <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
          <div className="container">
            <h1 className="page-title">Checkout</h1>
          </div>
        </div>
        <nav aria-label="breadcrumb" className="breadcrumb-nav">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="">Home</a></li>
              <li className="breadcrumb-item active" aria-current="page">Checkout</li>
            </ol>
          </div>
        </nav> */}
        <div className="page-content">
          <div className="checkout">
            <div className="container">
              <div className="checkout-discount">
                <form action="#">
                  <input type="text" className="form-control" required id="checkout-discount-input" />
                  <label htmlFor="checkout-discount-input" className="text-truncate">Have a coupon? <Link to="/cart"><span>Click here to enter your code</span></Link> </label>
                </form>
              </div>{/* End .checkout-discount */}
              <form onSubmit={(e)=>chack2 && chack3 === false  ? SubmitDataMessage2(e) :SubmitDataMessage1(e) }>
                <div className="row">
                  <div className="col-lg-9">
                    <h2 className="checkout-title">Billing Details</h2>{/* End .checkout-title */}
                    <div className="row">
                      <div className="col-sm-12">
                        <label>Full Name *</label>
                        <input type="text" className="form-control" required value={fname} onChange={(e)=>setfName(e.target.value)} placeholder="Enter Full Name"/>
                      </div>{/* End .col-sm-6 */}
                      {/* <div className="col-sm-6">
                        <label>Last Name *</label>
                        <input type="text" className="form-control" required />
                      </div> */}
                      {/* End .col-sm-6 */}
                    </div>{/* End .row */}
                    {/* <label>Company Name (Optional)</label>
                    <input type="text" className="form-control" /> */}
                    <label>Country *</label>
                    <input type="text" className="form-control" required  placeholder="Enter Country"  value={Country} onChange={(e)=>setCountry(e.target.value)} />
                    <label>Street address *</label>
                    <input type="text" className="form-control" required  placeholder="Enter Address including house no, Block and town"  value={Address} onChange={(e)=>setAddress(e.target.value)} />
                    <input type="text" className="form-control" placeholder="Appartments, suite, unit etc ..."   value={Address1} onChange={(e)=>setAddress1(e.target.value)} />
                    <div className="row">
                      <div className="col-sm-12">
                        <label>Town / City *</label>
                        <input type="text" className="form-control" required  placeholder="Enter City" value={City} onChange={(e)=>setCity(e.target.value)}/>
                      </div>{/* End .col-sm-6 */}
                      {/* <div className="col-sm-6">
                        <label>State / County *</label>
                        <input type="text" className="form-control" required />
                      </div> */}
                      {/* End .col-sm-6 */}
                    </div>{/* End .row */}
                    <div className="row">
                      {/* <div className="col-sm-6">
                        <label>Postcode / ZIP *</label>
                        <input type="text" className="form-control" required  placeholder="Enter Postcode / ZIP Number"  value={ZipPostal} onChange={(e)=>setZipPostal(e.target.value)}/>
                      </div>End .col-sm-6 */}
                      <div className="col-sm-12">
                        <label>Phone *</label>
                        <input type="tel" className="form-control"  placeholder="Enter Mobile Number"  required value={Phone} onChange={(e)=>setPhone(e.target.value)}  />
                      </div>{/* End .col-sm-6 */}
                    </div>{/* End .row */}
                    <label>Email address *</label>
                    <input type="email" className="form-control"  placeholder="Enter E-mail" required  value={Email} onChange={(e)=>setEmail(e.target.value)}  />
                    {/* <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="checkout-create-acc" />
                      <label className="custom-control-label" htmlFor="checkout-create-acc">Create an account?</label>
                    </div> */}
                    {/* End .custom-checkbox */}
                    {/* <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="checkout-diff-address" />
                      <label className="custom-control-label" htmlFor="checkout-diff-address">Ship to a different address?</label>
                    </div> */}
                    {/* End .custom-checkbox */}
                    <label>Booking notes (optional)</label>
                    <textarea className="form-control" cols={30} rows={4} placeholder="Notes about your order, e.g. special notes for delivery" defaultValue={""}  value={Order_Notes} onChange={(e)=>setOrder_Notes(e.target.value)} />
                  </div>{/* End .col-lg-9 */}
                  <aside className="col-lg-3">
                    <div className="summary">
                      <h3 className="summary-title">Your Booking</h3>{/* End .summary-title */}
                      <table className="table table-summary">
                        <thead>
                          <tr>
                            <th>Service</th>
                            <th>Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                                                    cart && cart.map((item,i)=>{
                                                      if(item.Price[0]){
                                                        return(
                                                          <tr>
                                                            <td><a href="#">{item.Product_Name}   </a>  <span style={{paddingLeft  :"30px"}}>{item.Size.length > 2 ? "Color : " :  "Size : "}  {item.Size}</span>  </td>
                                                            <th>x {item.Pieces}</th>
                                                            <td>Rs {(item.Total_Product_Price).toFixed(0)}</td>
                                                          </tr>
                                                          )
                                                        }
                                                        else{
                                                          return(
                                                            <tr>
                                                              <div  style={{maxWidth:"90%",maxHeight : "76px",overflow : "hidden"}}>
                                                              <td  style={{textAlign : "left"}}>
                                                                {item.Product_Name}
                                                              </td>
                                                              </div>
                                                              <th>x {item.Pieces}</th>
                                                              <td>Rs {(item.Total_Product_Price).toFixed(0)}</td>
                                                            </tr>
                                                            )
                                                        }
                                                        })
                                                          }
{/* //                                                           <tr>
//                                                           <td><a href="#">Beige knitted elastic runner shoes</a></td>
//                                                           <td>Rs 84.00</td>
//                                                         </tr>



//                                                             <div className="d-flex justify-content-between">  
//                                                                 {item.Price[0] ?
//         <p> <div>
//         <span className='text-truncate' style={{color : "black",width: "142px"}}>{item.Product_Name}</span>
//         <span style={{paddingLeft  :"30px"}}>{item.Size.length > 2 ? "Color : " :  "Size : "}  {item.Size}</span>
//       </div></p>

//  :
//  <p className='text-truncate' style={{color : "black",width: "142px"}}> {item.Product_Name }</p>
// } 

// <p>x {item.Pieces}</p>
//                     <p>Rs {(item.Total_Product_Price).toFixed(0)}</p>
//                                                             </div>
                                                  
// )
//                                                     })
//                                                 } */}
                          {/* <tr>
                            <td><a href="#">Beige knitted elastic runner shoes</a></td>
                            <td>Rs 84.00</td>
                          </tr>
                          <tr>
                            <td><a href="#">Blue utility pinafore denimdress</a></td>
                            <td>Rs 76,00</td>
                          </tr> */}
                          <tr className="summary-subtotal">
                            <td>Subtotal:</td>
                            <td></td>
                            <td>Rs {SubTotal}</td>
                          </tr>
                          
                          {/* End .summary-subtotal */}
                          {/* <tr className="summary-subtotal">
                            <td></td>
                            <td></td>


                            <td>{ admindataall > 0 ?
                                                            "Rs : "+admindataall
                                                            : 
                                                            <b>Without Tax</b>
                                                         }</td>
                          </tr> */}
                          <tr className="summary-subtotal">
                            <td>VAT</td>
                            <td></td>

                            <td>5 %</td>
                          </tr>
                          <tr className="summary-subtotal">
                            <td>Discount</td>
                            <td></td>

                            <td id="discount" ></td>
                          </tr>
                          <tr className="summary-total">
                            <td>Total:</td>
                            <td></td>

                            <td id="total">Rs </td>
                          </tr>{/* End .summary-total */}
                          {/* <tr className="summary-total">
                            <td>Deposit:</td>
                            <td></td>

                            <td>Rs 1000</td>
                          </tr> */}
                          {/* End .summary-total */}
                        </tbody>
                      </table>{/* End .table table-summary */}
                      <div className="accordion-summary" id="accordion-payment">
                       
                        
                        <div className="card">
                          <div className="card-header" id="heading-3">
                          <h2 className="card-title">
                              {/* <span style={{fontWeight : "bold", color : "black"}}>Note : (Booking is accepted only if you transfer the payment to the following account below.)</span>
                              <br />
                              <br /> */}
                             

                             <div className="card">
                          <div className="card-header" id="heading-3">
                            <h2 className="card-title">
                              {/* <a className="collapsed" role="button" value={true} data-toggle="collapse" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3"> */}
                              {/* <input type="radio" name="" id="" checked value={true} style={{marginRight: "10px"}}/> */}
                           
                              <span style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}} id={"changeIcon111"} ></span>   <h5 className="h6 mb-0">
                                       <input type="checkbox" name="vehicle3" value={chack2} onChange={()=>setchack21()} style={{marginTop: "-10px"}}/>           Cash on Hand</h5>

                               
                              {/* </a> */}
                            </h2>
                            <h2 className="card-title">
                             
                              <span style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}} id={"changeIcon111"} ></span>   <h5 className="h6 mb-0">
                                       <input type="checkbox" name="vehicle3" value={chack3} onChange={()=>setchack31()} style={{marginTop: "-10px"}}/>           Payment Online</h5>

                               
                              {/* </a> */}
                            </h2>
                          </div>{/* End .card-header */}
                          
                        </div>{/* End .card */}
                        
                       




{/* <span style={{fontWeight : "500", color : "black"}}>
                             <p>Bank Account : </p>
                             <br/>
                              Bank Name: [ RAK BANK ] <br />
                              Account Name: [FALCON RIDES CAR RENTAL] <br />
                              Account Number: [ 0352952262001 ]<br />
                              IBAN Number: [ AE200400000352952262001 ] 

                             </span>
                             <br /> */}



                            </h2>



                            

                          </div>{/* End .card-header */}
{/* <br/>
<br/> */}
                          <div id="collapse-3" className="collapse" aria-labelledby="heading-3" data-parent="#accordion-payment">
                            <div className="card-body">Quisque volutpat mattis eros. Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. 
                            </div>{/* End .card-body */}
                          </div>{/* End .collapse */}
                        </div>{/* End .card */}
                        
                        <div style={{display : "flex"}}  >
 <input type="checkbox" name="vehicle3" value={chack1} onChange={()=>setchack1(!chack1)} style={{marginTop: "-10px"}}/> 
                                                <span style={{color : "black" ,marginRight: "20px",marginTop: "-4px"}} id={"changeIcon111"} ></span>   <h5 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href={"#collapsebankssa1"} role="button" aria-expanded="false" aria-controls={"collapsebankssa1"} style={{color : "black"}}> 
                                              I have read and agree to the website terms and conditions *</a> <span style={{color  :"red",textDecoration:"underline",fontSize:"17px",cursor:"pointer"}} onClick={()=>props.history.push("/term-policy")}> Click Here</span></h5>
                                                        </div>

                                                

                                                        <div className="card">
                          <div className="card-header" id="heading-3">
                          <h2 className="card-title">
                              <span style={{fontWeight : "bold", color : "black"}}>Note : (Deposit 1000 Rs. Will be refunded after 15 days once the car is returned.)</span>
                             

                              {/* </a> */}
                            </h2>

</div>
</div>
{/* <GoSell
           gateway={{
             publicKey:"pk_live_Sac9BvWURLDCpI74YZd2jhE5",
             language:"en",
             contactInfo:true,
             supportedCurrencies:"all",
             supportedPaymentMethods:"all",
             saveCardOption:true,
             customerCards: true,
             notifications:'standard',
             backgroundImg: {
              url: 'imgURL',
              opacity: '0.5'
            },
            //  callback: this.callbackFunc,
             labels:{
                 cardNumber:"Card Number",
                 expirationDate:"MM/YY",
                 cvv:"CVV",
                 cardHolder:"Name on Card",
                 actionButton:"Pay"
             },
             style: {
                 base: {
                   color: '#535353',
                   lineHeight: '18px',
                   fontFamily: 'sans-serif',
                   fontSmoothing: 'antialiased',
                   fontSize: '16px',
                   '::placeholder': {
                     color: 'rgba(0, 0, 0, 0.26)',
                     fontSize:'15px'
                   }
                 },
                 invalid: {
                   color: 'red',
                   iconColor: '#fa755a '
                 }
             }
           }}
           customer={{
             first_name: fname,
             middle_name: "",
             last_name: Lname,
             email: Email,
             phone: {
                 country_code: "965",
                 number: Phone
             }
           }}
           order={{
             amount: Tot1,
             currency:"Rs",
             items:[{
               id:1,
               name:'item1',
               description: 'item1 desc',
               quantity:'x1',
               amount_per_unit:'Rs00.000',
               total_amount: 'Rs000.000'
             },
            ],
             shipping:null,
             taxes: null
           }}
           transaction={{
             mode: 'charge',
             charge:{
              saveCard: false,
              threeDSecure: true,
              description: "Test Description",
              statement_descriptor: "Sample",
              reference:{
                transaction: "txn_0001",
                order: "ord_0001"
              }},
              metadata:{},
              receipt:{
                email: false,
                sms: true
              },
              redirect: "/redirect",
              post: null,
            }}
           /> */}
                 {/* <button onClick={() => GoSell.openPaymentPage()}>open goSell Page</button>
                 <button onClick={() => GoSell.openLightBox()}>open goSell LightBox</button> */}

        {/* <button onClick={() => GoSellElements.submit()}>Submit</button> */}

                      </div>{/* End .accordion */}
                      <button type="submit" className="btn btn-outline-primary-2 btn-order btn-block">
                      <span className="btn-text">Book Now</span>
                        <span className="btn-hover-text">Proceed to Checkout</span>
                      </button>
                    </div>{/* End .summary */}
                  </aside>{/* End .col-lg-3 */}
                </div>{/* End .row */}
              </form>
            </div>{/* End .container */}
          </div>{/* End .checkout */}
        </div>{/* End .page-content */}
      </main>{/* End .main */}
    </div>
        )
    
}

export default Checkout;