// import { IconQuote } from "@tabler/icons-react";
import Img2 from "../images/testimonials/pfp1.jpg";
import Img3 from "../images/testimonials/pfp2.jpg";

import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

function Testimonials() {
  return (
    <>
      <section className="testimonials-section">
        <div className="container">
          <div className="testimonials-content">
            <div className="testimonials-content__title">
              <h4>Reviewed by People</h4>
              <h2>Client's Testimonials</h2>
              <p>
                Discover the positive impact we've made on the our clients by
                reading through their testimonials. Our clients have experienced
                our service and results, and they're eager to share their
                positive experiences with you.
              </p>
            </div>

            <div className="all-testimonials">
              <div className="all-testimonials__box">
                <span className="quotes-icon">
                  <FormatQuoteIcon width={60} height={60} style={{fontSize:"70px"}}/>
                </span>
                <p>
                  "I have rented a car from this company for more than one Year and half, the service is excellent and they are dealing in professional way with their customers. Moreover, the cars are clean and always well maintained.  Thank you Mr.Umer for the gentle communication.
 "
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={"https://lh3.googleusercontent.com/a-/ALV-UjUEyiHiCgsDjw_mz6lUncrqI7GOiC9qBF6uIgHlyZhVjA=w36-h36-p-rp-mo-br100"} alt="user_img" />
                    <span>
                      <h4>Hammam Hilal</h4>
                      {/* <p>Belgrade</p> */}
                    </span>
                  </div>
                </div>
              </div>

              <div className="all-testimonials__box box-2">
                <span className="quotes-icon">
                  <FormatQuoteIcon width={60} height={60}  style={{fontSize:"70px"}}/>
                </span>
                <p>
                  "Very affordable car rental company! Haven't gotten an issue since the time I rented a car here. They are very helpful when needed. Highly recommended! 👍🏼 …
"
                </p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={"https://lh3.googleusercontent.com/a-/ALV-UjVO8-S0wYOfja_fWD0D9kIOMTF_fO_0WAuUBe8oW3QQ5_Q=w36-h36-p-rp-mo-br100"} alt="user_img" />
                    <span>
                      <h4>Graciela Anne Reynoso</h4>
                      {/* <p>Novi Sad</p> */}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
